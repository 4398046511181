import { t } from '@lingui/macro'
import { Typography } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BillingAddressCard from '../../../components/billing-address-card'
import ChildCard from '../../../components/child/child-card'
import { NewChildBaseInformation } from '../../../components/child/child-form/child-base-information-form'
import { NewChildFurtherInformation } from '../../../components/child/child-form/child-further-information-form'
import { NewChildImportantInformation } from '../../../components/child/child-form/child-important-information-form'
import FormPage from '../../../components/form/form-page'
import GuardianCard from '../../../components/guardian/guardian-card'
import { NewGuardian } from '../../../components/guardian/guardian-form-fields'
import NewButton from '../../../components/new-button'
import TaxCertificates from '../../../components/tax-certificates'
import { getActiveContractOrUndefined } from '../../../helper/get-active-contract'
import { Address, Child } from '../../../types'
import { useInstitutionContext } from '../institution-context'
import { NewChild } from '../registration/form-pages/child-form-pages/new-child-context'
import { useGuardianContext } from './guardian-context'
import { identity } from 'remeda'
const { Title } = Typography

const GuardianFamilyPage = () => {
  const { guardian, family, fetchFamily } = useGuardianContext()
  const { institution } = useInstitutionContext()
  const navigate = useNavigate()

  useEffect(() => {
    const awaitFetchFamily = async () => {
      await fetchFamily()
    }
    awaitFetchFamily()
  }, [fetchFamily])

  const guardians = family?.guardians
  const person1Data: NewGuardian | undefined =
    guardians && guardians.length >= 1 ? { ...guardians[0], soleCustodyPdf: null, soleCustodyPdfName: null } : undefined
  const person2Data =
    guardians && guardians.length >= 2 ? { ...guardians[1], soleCustodyPdf: null, soleCustodyPdfName: null } : undefined

  const updateGuardianData = async (data: NewGuardian) => {
    const body = JSON.stringify(data)
    await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/guardian-data?guardianId=${data.id}`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
        body,
      }
    )
    fetchFamily()
  }

  const isNewBillingAddress = (address: Address) => {
    if (family?.billingAddress.id === address.id) {
      return false
    } else return true
  }

  const isGuardianAddress = (address: Address) => {
    if (person1Data?.address.id === address.id || person2Data?.address.id === address.id) {
      return true
    }
    return false
  }

  const updateBillingAddress = async (data: Address) => {
    const body = JSON.stringify(data)

    if (isNewBillingAddress(data)) {
      if (!isGuardianAddress(data)) {
        await fetch(`${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/address-data`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
          },
          body,
        })
      }

      await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/billing-address?billingAddressId=${data.id}&familyId=${family?.id}`,
        {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
          },
          body,
        }
      )
    } else {
      await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/address-data?addressId=${data.id}`,
        {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
          },
          body,
        }
      )
    }
    fetchFamily()
  }

  const updateChild = async (
    information: NewChildBaseInformation | NewChildFurtherInformation | NewChildImportantInformation,
    childId: string
  ) => {
    if (family) {
      const child = family.children.find((child) => child.id === childId)
      if (child) {
        const updatedChild: Child = {
          ...child,
          ...information,
          ...('healthInsuranceName' in information
            ? {
                healthInsurance: joinInsurance('healthInsurance', information),
                liablityInsurance: joinInsurance('liablityInsurance', information),
              }
            : {}),
        }
        const body = JSON.stringify(updatedChild)

        await fetch(`${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/child-data`, {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
          },
          body,
        })
        fetchFamily()
      }
    }
  }

  const getGuardianAddresses = (): Address[] => family?.guardians?.map((g) => g.address) || []

  return (
    <FormPage title={t({ message: 'Familie' })}>
      <>
        <Title level={3}>{t({ message: 'Erziehungsberechtigte' })}</Title>
        {person1Data && (
          <GuardianCard
            isSecondParent={true}
            onSubmitGuardianInformation={updateGuardianData}
            guardian={person1Data}
            registrationConfiguration={institution!.registrationConfig}
            institutionFeatures={institution?.features}
          />
        )}
        {person2Data && (
          <GuardianCard
            isSecondParent={true}
            onSubmitGuardianInformation={updateGuardianData}
            guardian={person2Data}
            registrationConfiguration={institution!.registrationConfig}
            institutionFeatures={institution?.features}
          />
        )}

        <Title
          level={3}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {t({ message: 'Kinder' })}
          <NewButton onClick={() => navigate('../new-child-form/base-information')} />
        </Title>
        {family &&
          family.children.map((child) => (
            <ChildCard
              guardian={guardian}
              family={family}
              key={child.id}
              onSubmitEditedBaseInformation={updateChild}
              onSubmitEditedFurtherInformation={updateChild}
              onSubmitEditedImportantInformation={updateChild}
              child={mapChildToFormChild(child)}
            />
          ))}
        <Title level={3}>{t({ message: 'Rechnungsadresse' })}</Title>
        {family && (
          <BillingAddressCard
            billingAddress={family.billingAddress}
            familyId={family.id}
            guardianAddresses={getGuardianAddresses()}
            institutionFeatures={institution?.features}
            onSubmitBillingAddress={updateBillingAddress}
          />
        )}
        {family && <TaxCertificates />}
      </>
    </FormPage>
  )
}

export default GuardianFamilyPage

const mapChildToFormChild = (child: Child): NewChild => {
  const currentContract = getActiveContractOrUndefined(child.contracts)
  const healthInsurance = splitInsurance(child.healthInsurance)
  const liabilityInsurance = splitInsurance(child.liablityInsurance)
  return {
    id: child.id,
    baseInformation: {
      familyId: child.familyId,
      firstName: child.firstName,
      lastName: child.lastName,
      sex: child.sex ? child.sex : 'other',
      dateOfBirth: new Date(child.dateOfBirth),
      nationality: child.nationality ? child.nationality : 'other',
      familyLanguage: child.familyLanguage,
      religion: child.religion,
      livesWith: child.livesWith,
      class: currentContract?.class,
      kinderGarten: currentContract?.kinderGarten,
      schoolId: currentContract?.schoolId,
      pickUp: child.pickUp,
      teacherName: currentContract?.teacherName,
      teacherContact: currentContract?.teacherContact,
      healthInsuranceName: healthInsurance.name,
      healthInsuranceNumber: healthInsurance.number,
      liablityInsuranceName: liabilityInsurance.name,
      liablityInsuranceNumber: liabilityInsurance.number,
      familyDoctorName: child.familyDoctorName,
      familyDoctorAddress: child.familyDoctorAddress,
      dentistName: child.dentistName,
      dentistAddress: child.dentistAddress,
      schoolLevel: currentContract?.schoolLevel || 'school',
      familyDoctorPhone: child.familyDoctorPhone,
      generalHealth: child.generalHealth,
      diseases: child.diseases,
      hasAllergies: child.hasAllergies,
      allergies: child.allergies,
      needsMedicaments: child.needsMedicaments,
      neededMedicaments: child.neededMedicaments,
      remarks: child.remarks,
      isOutOfTown: child.isOutOfTown,
    },
    importantInformation: {
      wayHome: child.wayHome,
      hasCollectors: child.hasCollectors,
      collectorsStrategy: child.collectorsStrategy,
      collectors: child.collectors,
      allowedToBeAloneOnSchoolYard: child.allowedToBeAloneOnSchoolYard,
      allowedToDoHomework: child.allowedToDoHomework,
      allowedToBeTransportedByCar: child.allowedToBeTransportedByCar,
      allowedToReceiveMedicalHelp: child.allowedToReceiveMedicalHelp,
      allowedToUseTickSpray: child.allowedToUseTickSpray,
      allowedToUsePublicTransport: child.allowedToUsePublicTransport,
      allowedToBePhotographedForInternalUse: child.allowedToBePhotographedForInternalUse,
      allowedToBePhotographedForAds: child.allowedToBePhotographedForAds,
      allowedToBePhotographedForOtherKids: child.allowedToBePhotographedForOtherKids,
      allowedToContactTeachers: child.allowedToContactTeachers,
      allowedToContactSchoolSocialWorkers: child.allowedToContactSchoolSocialWorkers,
      busLineAndTimeforWayHome: child.busLineAndTimeforWayHome,
      schoolOfferOverLunch: currentContract?.schoolOfferOverLunch,
      menuSelection: child.menuSelection,
      isVaccinated: child.isVaccinated,
      isTakingNap: child.isTakingNap,
      isAllowedToBeAloneOutside: child.isAllowedToBeAloneOutside,
      isAllowedToLeaveYardDuringLunch: child.isAllowedToLeaveYardDuringLunch,
      visitsOtherInstitution: child.visitsOtherInstitution,
      visitsPhisioTherapy: child.visitsPhisioTherapy,
    },
    furtherInformation: {
      contactPreference: child.contactPreference,
      emergencyContactFirstName: child.emergencyContactFirstName,
      emergencyContactLastName: child.emergencyContactLastName,
      emergencyContactPhone: child.emergencyContactPhone,
      emergencyContactRemarks: child.emergencyContactRemarks,
    },
  }
}

const splitInsurance = (insurance: string | undefined): { name?: string; number?: string } => {
  const splitted = insurance?.split(',').map((item) => item.trim())
  const name = splitted?.[0]
  const number = splitted?.[1]
  return {
    name: name === 'undefined' ? undefined : name,
    number: number === 'undefined' ? undefined : number,
  }
}

const joinInsurance = <T extends string>(
  key: T,
  input: Partial<Record<`${T}Name` | `${T}Number`, string | undefined>>
): string => [input[`${key}Name`], input[`${key}Number`]].filter(identity).join(', ')
