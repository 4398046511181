import { FilePdfFilled, LoadingOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Button } from 'antd'
import { useState } from 'react'
import { downloadAutheticatedFile } from '../helper/download-authenticated-file'

const TaxCertificateDownloadLink = ({
  familyId,
  institutionId,
  year,
}: {
  familyId: string
  year: number
  institutionId: string
}) => {
  const [downloading, setDownloading] = useState(false)
  const filename = t({ message: 'Steuerbescheinigung' }) + `_${year}.pdf`

  const handleClickOnDownload = async () => {
    setDownloading(true)
    await downloadAutheticatedFile(
      `/api/common/${institutionId}/tax-certificate-download?familyId=${familyId}&year=${year}`,
      filename,
      'application/pdf'
    )
    setDownloading(false)
  }

  return (
    <Button type="link" onClick={handleClickOnDownload}>
      {downloading ? <LoadingOutlined /> : <FilePdfFilled />} <u>{filename}</u>
    </Button>
  )
}

export default TaxCertificateDownloadLink
