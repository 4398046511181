import { FileOpener } from '@capacitor-community/file-opener'
import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'

export const downloadAutheticatedFile = async (path: string, filename: string, mimeType: string) => {
  const url = `${process.env.REACT_APP_LEOBA_SERVER}${path}`

  if (Capacitor.isNativePlatform()) {
    const res = await fetch(url)
    const blob = await res.blob()
    openPdfOnNativePlatform(blob, filename, mimeType)
  } else {
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

const openPdfOnNativePlatform = (contractBlob: Blob, filename: string, mimeType: string) => {
  const reader = new FileReader()
  reader.onloadend = async function () {
    const base64data = reader.result as string
    await Filesystem.writeFile({
      path: filename,
      data: base64data,
      directory: Directory.Cache,
    })
    const getUriResult = await Filesystem.getUri({
      directory: Directory.Cache,
      path: filename,
    })
    const path = getUriResult.uri
    FileOpener.open({ filePath: path, contentType: mimeType })
  }
  reader.readAsDataURL(contractBlob)
}
