import { Trans } from '@lingui/macro'
import { Card } from 'antd'
import { toDateTimeString } from '../../helper/format-date'
import { ChildMessage } from '../../types'
import styles from './message-card.module.css'
import mime from 'mime'
import { AuthenticatedImage } from '../authenticated-image'
import { downloadAutheticatedFile } from '../../helper/download-authenticated-file'
import { FilePdfFilled } from '@ant-design/icons'

const MessageCard = ({
  message,
  highlight,
  familyId,
  institutionId,
}: {
  message: ChildMessage
  highlight?: boolean
  familyId: string
  institutionId: string
}) => {
  return (
    <Card
      size="small"
      key={message.id}
      style={{
        width: '100%',
        marginBottom: '0.5em',
        background: highlight ? '#adedd2' : 'white',
      }}
      title={
        <div
          style={{
            flexWrap: 'wrap',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>
            {message.senderRole === 'Guardian' ? (
              <Trans>Gesendete Nachricht</Trans>
            ) : (
              <Trans>Nachricht von Institution</Trans>
            )}
          </div>
          <div>{toDateTimeString(new Date(message.dateTime))}</div>
        </div>
      }
    >
      <div className={styles.cardBody}>
        <span style={{ whiteSpace: 'pre-wrap' }}>{message.message}</span>
        {message.files.map((file) => {
          const downloadLink = `/api/common/${institutionId}/message-files?childId=${message.childId}&familyId=${familyId}&id=${file.id}`
          const mimeType = mime.getType(file.name)
          const download = () =>
            downloadAutheticatedFile(downloadLink, file.name, mime.getType(file.name) ?? 'application/octet-stream')
          if (mimeType === 'application/pdf') {
            return (
              <span onClick={download} style={{ cursor: 'pointer' }}>
                <FilePdfFilled key={file.id} style={{ fontSize: '20px' }} /> {file.name}
              </span>
            )
          }

          return (
            <AuthenticatedImage
              key={file.id}
              onClick={download}
              className={styles.cardBodyImage}
              path={downloadLink}
              alt={file.name}
            />
          )
        })}
      </div>
    </Card>
  )
}

export default MessageCard
