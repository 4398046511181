import { Card } from 'antd'
import { toDateTimeString } from '../../helper/format-date'
import { translateLogbookType } from '../../helper/translate-logbook-type'
import { ChildLogbook } from '../../types'
import styles from './logbook-card.module.css'
import { AuthenticatedImage } from '../authenticated-image'
import { downloadAutheticatedFile } from '../../helper/download-authenticated-file'

type Props = {
  childLogbook: ChildLogbook
  institutionId: string
  familyId: string
}

const LogbookCard = ({ childLogbook, institutionId, familyId }: Props) => {
  return (
    <Card
      size="small"
      className={styles.card}
      key={childLogbook.id}
      title={
        <div
          style={{
            flexWrap: 'wrap',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>{translateLogbookType(childLogbook.logbookType)}</div>
          <div>{toDateTimeString(new Date(childLogbook.dateTime))}</div>
        </div>
      }
    >
      <div className={styles.cardBody}>
        {childLogbook.description}
        {childLogbook.images.map((image) => {
          const path = `/api/common/${institutionId}/logbook-images?childId=${childLogbook.childId}&familyId=${familyId}&id=${image.id}`
          return (
            <AuthenticatedImage
              key={image.id}
              onClick={() => downloadAutheticatedFile(path, image.name, 'image/jpeg')}
              className={styles.cardBodyImage}
              path={path}
              alt={image.name}
            />
          )
        })}
      </div>
    </Card>
  )
}

export default LogbookCard
