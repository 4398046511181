import { CookieHandlerInterface } from 'supertokens-website/utils/cookieHandler/types'
import { appInfo } from './appInfo'
import { getCookieHandler } from './cookie-handler'
import { getSupertokensTranslations } from './supertokens-translations'
import EmailPassword from 'supertokens-web-js/recipe/emailpassword'
import EmailVerification from 'supertokens-web-js/recipe/emailverification'
import Session from 'supertokens-web-js/recipe/session'
import { Capacitor } from '@capacitor/core'

export const frontendConfig = (locale: string) => {
  const theAppInfo = appInfo(process.env.REACT_APP_LEOBA_SERVER!)
  const translations = getSupertokensTranslations(locale)
  return {
    appInfo: theAppInfo,
    cookieHandler: (original: CookieHandlerInterface) => getCookieHandler(original),
    recipeList: [
      EmailPassword.init({
        preAPIHook: async (context) => {
          if (context.requestInit.headers) {
            const newHeaders = new Headers(context.requestInit.headers)
            newHeaders.append('locale', locale)
            newHeaders.append('source', 'eltern-app')
            const morphEmail = localStorage.getItem('morphEmail')
            if (morphEmail) {
              newHeaders.append('morphEmail', morphEmail)
            }
            context.requestInit.headers = newHeaders
          }
          return { url: context.url, requestInit: context.requestInit }
        },
      }),
      EmailVerification.init({}),
      Session.init({ tokenTransferMethod: Capacitor.isNativePlatform() ? 'header' : 'cookie' }),
    ],
    languageTranslations: {
      defaultLanguage: locale,
      translations,
    },
  }
}
