import { nanoid } from 'nanoid'
import create from 'zustand'
import { persist } from 'zustand/middleware'
import { NewChildBaseInformation } from '../../../components/child/child-form/child-base-information-form'
import { NewChildFurtherInformation } from '../../../components/child/child-form/child-further-information-form'
import { NewChildImportantInformation } from '../../../components/child/child-form/child-important-information-form'
import { NewGuardian } from '../../../components/guardian/guardian-form-fields'
import { Address, AgeGroup, Child, Family, SignatureData, TaxInformation } from '../../../types'
import { NewChild } from './form-pages/child-form-pages/new-child-context'
import { dateString } from '../../../helper/date-string'
import { FamilyInformation } from '../../../components/family-information-form'

interface RegistrationContext {
  institutionId: string | undefined
  guardian1: NewGuardian | undefined
  guardian2: NewGuardian | undefined
  children: NewChild[]
  familyId: string
  hasMoreThanOneChild: boolean | undefined
  soleCustody: boolean | undefined
  billingAddress: Address | undefined
  signatureData: SignatureData | undefined
  taxInformation: TaxInformation | undefined
  isOutOfTown: boolean | undefined
  familyInformation: FamilyInformation | undefined
  setInstitutionId: (institutionId: string) => void
  getGuardian1Data: () => NewGuardian | undefined
  getGuardian2Data: () => NewGuardian | undefined
  setGuardian1Data: (data: NewGuardian) => void
  setGuardian2Data: (data: NewGuardian) => void
  soleCustodyFileContent: () => string | undefined
  setSoleCustody: (hasSoleCustody: boolean) => void
  getChildren: () => NewChild[]
  addChild: (
    newChildBaseInformation: NewChildBaseInformation,
    newChildImportantInformation: NewChildImportantInformation,
    newChildFurtherInformation: NewChildFurtherInformation,
    moduleBooking: ModuleBooking
  ) => Promise<void>
  updateChildBaseInformation: (data: NewChildBaseInformation, id: string) => void
  updateChildFurtherInformation: (furtherInformation: NewChildFurtherInformation, id: string) => void
  updateChildImportantInformation: (importantInformation: NewChildImportantInformation, id: string) => void
  updateChildModules: (moduleBooking: ModuleBooking, id: string) => void
  getGuardianAddresses: () => Address[]
  setBillingAddress: (data: Address) => void
  getBillingAddress: () => Address | undefined
  setSignatureData: (data: SignatureData) => void
  getSignatureData: () => SignatureData | undefined
  setTaxInformation: (data: TaxInformation | undefined) => void
  getTaxInformation: () => TaxInformation | undefined
  setFamilyInformation: (data: FamilyInformation) => void
  getFamilyInformation: () => FamilyInformation | undefined
  getFamily: () => Family
  setOutOfTown: (isOutOfTown: boolean) => void
  setHasMoreThanOneChild: (hasMoreThanOneChild: boolean) => void
  reset: () => void
}

export type ModuleBooking = {
  flexibleBooking: boolean
  startDate?: Date
  adjustmentPeriodStartDate?: Date
  locationId?: string
  ageGroup?: AgeGroup
  bookings: {
    moduleId: string
    ageGroupId: string
    comingFrom?: string
    comingFromTime?: string
    goingTo?: string
    goingToTime?: string
  }[]
}

const getInitialState = () => ({
  institutionId: undefined,
  guardian1: undefined,
  guardian2: undefined,
  children: [],
  familyId: nanoid(),
  hasMoreThanOneChild: undefined,
  soleCustody: false,
  billingAddress: undefined,
  signatureData: undefined,
  taxInformation: undefined,
  isOutOfTown: undefined,
  familyInformation: undefined,
})

export const useRegistrationContext = create<RegistrationContext>()(
  persist(
    (set, get) => ({
      ...getInitialState(),
      setInstitutionId: (institutionId: string): void => {
        set(() => {
          return { institutionId }
        })
      },
      getChildren: (): NewChild[] => {
        return get().children
      },
      setGuardian1Data: (data) => {
        set(() => {
          return {
            guardian1: data,
            billingAddress: data.address,
          }
        })
      },
      setGuardian2Data: (data) => {
        set(() => {
          return {
            guardian2: data,
          }
        })
      },
      getGuardian1Data: (): NewGuardian | undefined => {
        return get().guardian1
      },
      getGuardian2Data: (): NewGuardian | undefined => {
        return get().guardian2
      },
      setSoleCustody(hasSoleCustody) {
        set(() => {
          return {
            soleCustody: hasSoleCustody,
          }
        })
      },
      updateChildBaseInformation: (baseInformation: NewChildBaseInformation, id: string) =>
        set((state) => {
          return {
            children: state.children.map((child) => {
              if (child.id === id) {
                return { ...child, baseInformation }
              }
              return child
            }),
          }
        }),
      updateChildFurtherInformation: (furtherInformation: NewChildFurtherInformation, id: string) =>
        set((state) => {
          return {
            children: state.children.map((child) => {
              if (child.id === id) {
                return { ...child, furtherInformation }
              }
              return child
            }),
          }
        }),
      updateChildImportantInformation: (importantInformation: NewChildImportantInformation, id: string) =>
        set((state) => {
          return {
            children: state.children.map((child) => {
              if (child.id === id) {
                return { ...child, importantInformation }
              }
              return child
            }),
          }
        }),
      updateChildModules: (moduleBooking: ModuleBooking, id: string) =>
        set((state) => {
          return {
            children: state.children.map((child) => {
              if (child.id === id) {
                return { ...child, moduleBooking }
              }
              return child
            }),
          }
        }),
      addChild: async (
        newChildBaseInformation: NewChildBaseInformation,
        newChildImportantInformation: NewChildImportantInformation,
        newChildFurtherInformation: NewChildFurtherInformation,
        moduleBooking: ModuleBooking
      ) =>
        set((state) => {
          return {
            children: [
              ...state.children,
              {
                id: nanoid(),
                baseInformation: newChildBaseInformation,
                importantInformation: newChildImportantInformation,
                furtherInformation: newChildFurtherInformation,
                moduleBooking: moduleBooking,
              },
            ],
          }
        }),
      getGuardianAddresses: (): Address[] => {
        const guardianAddresses: Address[] = []
        const person1 = get().guardian1
        const person2 = get().guardian2

        if (person1) {
          guardianAddresses.push(person1.address)
        }

        if (person2) {
          guardianAddresses.push(person2.address)
        }

        return guardianAddresses
      },
      getFamily: (): Family => {
        const guardian1 = get().getGuardian1Data()
        const guardian2 = get().getGuardian2Data()
        return convertRegistrationToDomainFamily(
          get().familyId,
          get().children,
          get().billingAddress,
          get().signatureData,
          guardian1,
          guardian2,
          get().hasMoreThanOneChild,
          get().familyInformation
        )
      },
      soleCustodyFileContent: () => {
        return get().getGuardian1Data()?.soleCustodyPdf || undefined
      },
      setBillingAddress(billingAddress) {
        set(() => {
          return { billingAddress }
        })
      },
      getBillingAddress: () => {
        return get().billingAddress || undefined
      },
      setSignatureData(signatureData) {
        set(() => {
          return {
            signatureData,
          }
        })
      },
      getSignatureData: () => {
        return get().signatureData || undefined
      },
      setTaxInformation(taxInformation) {
        set(() => {
          return { taxInformation }
        })
      },
      getTaxInformation() {
        return get().taxInformation || undefined
      },
      getFamilyInformation() {
        return get().familyInformation || undefined
      },
      setFamilyInformation(data) {
        set(() => {
          return { familyInformation: data }
        })
      },
      setOutOfTown(isOutOfTown: boolean) {
        set(() => {
          return { isOutOfTown }
        })
      },
      setHasMoreThanOneChild(hasMoreThanOneChild: boolean) {
        set(() => {
          return { hasMoreThanOneChild }
        })
      },
      reset: () => {
        set(getInitialState())
      },
    }),

    {
      name: 'registration',
    }
  )
)

const convertNewChildToDomainChild =
  (familyId: string, signatureData?: SignatureData) =>
  (child: NewChild): Child => {
    return {
      id: child.id,
      familyId: familyId,
      firstName: child.baseInformation.firstName,
      lastName: child.baseInformation.lastName,
      dateOfBirth: child.baseInformation.dateOfBirth,
      nationality: child.baseInformation.nationality,
      sex: child.baseInformation.sex,
      familyLanguage: child.baseInformation.familyLanguage,
      religion: child.baseInformation.religion,
      livesWith: child.baseInformation.livesWith,
      pickUp: child.baseInformation.pickUp,
      healthInsurance:
        child.baseInformation.healthInsuranceName && child.baseInformation.healthInsuranceNumber
          ? `${child.baseInformation.healthInsuranceName}, ${child.baseInformation.healthInsuranceNumber}`
          : child.baseInformation.healthInsuranceName || child.baseInformation.healthInsuranceNumber || undefined,
      liablityInsurance:
        child.baseInformation.liablityInsuranceName && child.baseInformation.liablityInsuranceNumber
          ? `${child.baseInformation.liablityInsuranceName}, ${child.baseInformation.liablityInsuranceNumber}`
          : child.baseInformation.liablityInsuranceName || child.baseInformation.liablityInsuranceNumber || undefined,
      familyDoctorName: child.baseInformation.familyDoctorName,
      familyDoctorAddress: child.baseInformation.familyDoctorAddress,
      familyDoctorPhone: child.baseInformation.familyDoctorPhone,
      dentistName: child.baseInformation.dentistName,
      dentistAddress: child.baseInformation.dentistAddress,
      isOutOfTown: child.baseInformation.isOutOfTown,
      generalHealth: child.baseInformation.generalHealth,
      diseases: child.baseInformation.diseases,
      hasAllergies: child.baseInformation.hasAllergies,
      allergies: child.baseInformation.allergies,
      needsMedicaments: child.baseInformation.needsMedicaments,
      neededMedicaments: child.baseInformation.neededMedicaments,
      remarks: child.baseInformation.remarks,
      contactPreference: child.furtherInformation.contactPreference,
      emergencyContactFirstName: child.furtherInformation.emergencyContactFirstName,
      emergencyContactLastName: child.furtherInformation.emergencyContactLastName,
      emergencyContactPhone: child.furtherInformation.emergencyContactPhone,
      emergencyContactRemarks: child.furtherInformation.emergencyContactRemarks,
      wayHome: child.importantInformation.wayHome,
      hasCollectors: child.importantInformation.hasCollectors ? child.importantInformation.hasCollectors : false,
      collectorsStrategy: child.importantInformation.collectorsStrategy,
      allowedToBeAloneOnSchoolYard: child.importantInformation.allowedToBeAloneOnSchoolYard,
      allowedToDoHomework: child.importantInformation.allowedToDoHomework,
      allowedToBeTransportedByCar: child.importantInformation.allowedToBeTransportedByCar,
      allowedToReceiveMedicalHelp: child.importantInformation.allowedToReceiveMedicalHelp,
      allowedToUseTickSpray: child.importantInformation.allowedToUseTickSpray,
      allowedToUsePublicTransport: child.importantInformation.allowedToUsePublicTransport,
      allowedToBePhotographedForInternalUse: child.importantInformation.allowedToBePhotographedForInternalUse,
      allowedToBePhotographedForAds: child.importantInformation.allowedToBePhotographedForAds,
      allowedToBePhotographedForOtherKids: child.importantInformation.allowedToBePhotographedForOtherKids,
      allowedToContactTeachers: child.importantInformation.allowedToContactTeachers,
      allowedToContactSchoolSocialWorkers: child.importantInformation.allowedToContactSchoolSocialWorkers,
      visitsOtherInstitution: child.importantInformation.visitsOtherInstitution,
      visitsPhisioTherapy: child.importantInformation.visitsPhisioTherapy,
      isVaccinated: child.importantInformation.isVaccinated,
      isTakingNap: child.importantInformation.isTakingNap,
      isAllowedToBeAloneOutside: child.importantInformation.isAllowedToBeAloneOutside,
      isAllowedToLeaveYardDuringLunch: child.importantInformation.isAllowedToLeaveYardDuringLunch,
      busLineAndTimeforWayHome: child.importantInformation.busLineAndTimeforWayHome,
      menuSelection: child.importantInformation.menuSelection,

      collectors: child.importantInformation.collectors,
      contracts: [
        {
          createdAt: signatureData?.signatureDate || new Date(),
          id: signatureData?.contractId || nanoid(),
          locationId: child.moduleBooking?.locationId!,
          childId: child.id,
          state: 'new',
          startDate: child.moduleBooking?.startDate || signatureData?.signatureDate || new Date(),
          adjustmentPeriodStartDate: child.moduleBooking?.adjustmentPeriodStartDate,
          ageGroupId: child.moduleBooking?.ageGroup?.id || '',
          bookedModules: child.moduleBooking ? child.moduleBooking?.bookings : [],
          schoolLevel: child.baseInformation.schoolLevel,
          class: child.baseInformation.class,
          schoolId: child.baseInformation.schoolId,
          kinderGarten: child.baseInformation.kinderGarten,
          teacherName: child.baseInformation.teacherName,
          teacherContact: child.baseInformation.teacherContact,
          schoolOfferOverLunch: child.importantInformation.schoolOfferOverLunch,
        },
      ],
    }
  }

const convertRegistrationToDomainFamily = (
  familyId: string,
  newChildren: NewChild[],
  billingAddress?: Address,
  signatureData?: SignatureData,
  guardian1?: NewGuardian,
  guardian2?: NewGuardian,
  overriceHasMoreThanOneChild?: boolean,
  familyInformation?: FamilyInformation
): Family => {
  const guardians = [guardian1, guardian2].filter(isGuardian)
  const children = newChildren.map(convertNewChildToDomainChild(familyId, signatureData))
  const hasMoreThanOneChild =
    children.filter(
      (ch) =>
        ch.contracts.some((contract) => contract.schoolLevel === 'kindergarten') ||
        ch.contracts.some((contract) => contract.schoolLevel === 'school')
    ).length > 1
  return {
    id: familyId,
    guardians,
    children,
    billingAddress: billingAddress || guardians[0].address,
    hasMoreThanOneChild: hasMoreThanOneChild || overriceHasMoreThanOneChild,
    lastChecked: dateString(new Date()),
    applyForSubsidies: familyInformation?.applyForSubsidies || false,
  }
}

const isGuardian = (guardian: NewGuardian | undefined): guardian is NewGuardian => {
  return !!guardian
}
