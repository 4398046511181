import { LoadingOutlined } from '@ant-design/icons'
import { Capacitor } from '@capacitor/core'
import { ComponentProps, useEffect, useState } from 'react'

const isNative = Capacitor.isNativePlatform()

export const AuthenticatedImage = ({ path, alt, ...props }: { path: string } & Omit<ComponentProps<'img'>, 'src'>) => {
  const [url, setUrl] = useState<string | null>(isNative ? null : `${process.env.REACT_APP_LEOBA_SERVER}${path}`)
  useEffect(() => {
    if (!isNative) return
    const promise = (async () => {
      const res = await fetch(`${process.env.REACT_APP_LEOBA_SERVER}${path}`)
      const blob = await res.blob()
      const url = URL.createObjectURL(blob)
      setUrl(url)
      return url
    })()
    return () => {
      promise.then((url) => {
        URL.revokeObjectURL(url)
      })
    }
  }, [path])

  return url ? (
    <img src={url} alt={alt} {...props} />
  ) : (
    <span>
      <LoadingOutlined />
    </span>
  )
}
