import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Card, Col, Input, Modal, Radio, Row, Select, Typography } from 'antd'
import Button from 'antd/es/button'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import translateCollectorsStrategy from '../../../helper/translate-collectors-strategy'
import { useInstitutionContext } from '../../../routes/institution/institution-context'
import { Child, Contract } from '../../../types'
import ButtonSection from '../../form/button-section'
import { Field } from '../../form/field'
import YesNoRadioButtons from '../../form/yes-no-radio-buttons'
import SingleLineTextInput from '../../form/single-line-text-input'
import translateMenuSelection from '../../../helper/translate-menu-selection'
const { Text } = Typography

export type NewChildImportantInformation = Pick<
  Child,
  | 'wayHome'
  | 'busLineAndTimeforWayHome'
  | 'menuSelection'
  | 'hasCollectors'
  | 'collectorsStrategy'
  | 'collectors'
  | 'allowedToBeAloneOnSchoolYard'
  | 'allowedToDoHomework'
  | 'allowedToBeTransportedByCar'
  | 'allowedToReceiveMedicalHelp'
  | 'allowedToUseTickSpray'
  | 'allowedToUsePublicTransport'
  | 'allowedToBePhotographedForInternalUse'
  | 'allowedToBePhotographedForAds'
  | 'allowedToBePhotographedForOtherKids'
  | 'allowedToContactTeachers'
  | 'allowedToContactSchoolSocialWorkers'
  | 'visitsOtherInstitution'
  | 'visitsPhisioTherapy'
  | 'isVaccinated'
  | 'isTakingNap'
  | 'isAllowedToBeAloneOutside'
  | 'isAllowedToLeaveYardDuringLunch'
> &
  Pick<Contract, 'schoolOfferOverLunch'>

type Props = {
  handleSubmit: (data: NewChildImportantInformation) => void
  submitButtonText?: string
  handlePrevious?: () => void
  defaultValues?: NewChildImportantInformation
}

const ChildImportantInformationForm = ({ handleSubmit, defaultValues, handlePrevious, submitButtonText }: Props) => {
  const {
    register,
    formState: { errors },
    watch,
    control,
    setValue,
    handleSubmit: formHandleSubmit,
  } = useForm<NewChildImportantInformation>({
    defaultValues,
  })
  const { fields, append, remove } = useFieldArray<NewChildImportantInformation>({
    control,
    name: 'collectors',
  })

  const { institution } = useInstitutionContext()
  const askForBusAndTimeOnWayHomeMandatory = institution?.registrationConfig.askForBusAndTimeOnWayHome === 'mandatory'
  const askForBusAndTimeOnWayHome =
    askForBusAndTimeOnWayHomeMandatory || institution?.registrationConfig.askForBusAndTimeOnWayHome === 'optional'
  const askForSchollOfferOverLunchMandatory = institution?.registrationConfig.askForSchoolOfferOverLunch === 'mandatory'
  const askForSchoolOfferOverLunch =
    askForSchollOfferOverLunchMandatory || institution?.registrationConfig.askForSchoolOfferOverLunch === 'optional'
  const askForMenuSelectionMandatory = institution?.registrationConfig.askForMenuSelection === 'mandatory'
  const askForMenuSelection =
    askForMenuSelectionMandatory || institution?.registrationConfig.askForMenuSelection === 'optional'
  const askForWayHome = institution?.registrationConfig.askForWayHome ? true : false

  const [collectorFirstName, setCollectorFirstName] = useState('')
  const [collectorLastName, setCollectorLastName] = useState('')
  const [collectorComment, setCollectorComment] = useState('')
  const [isCollectorsModalOpen, setIsCollectorsModalOpen] = useState(false)

  const wayHome = watch('wayHome')
  const collectorsStrategy = watch('collectorsStrategy', undefined)

  const openCollectorModal = () => {
    setIsCollectorsModalOpen(true)
  }

  const closeCollectorModal = () => {
    setIsCollectorsModalOpen(false)
    clearCollector()
  }

  const clearCollector = () => {
    setCollectorFirstName('')
    setCollectorLastName('')
    setCollectorComment('')
  }

  const handleCollectorsModalOk = () => {
    addCollector()
    closeCollectorModal()
  }

  const handleCollectorsModalCancel = () => {
    closeCollectorModal()
  }

  useEffect(() => {
    if (institution?.registrationConfig.askForWayHome === false) {
      setValue('wayHome', 'fetched')
    }
  }, [institution, setValue])

  const onSubmitHandler = formHandleSubmit((values) => {
    const submitValues = {
      ...values,
      collectorsStrategy: values.wayHome === 'unfetched' ? 'not_specified' : values.collectorsStrategy,
    }
    handleSubmit(submitValues)
  })

  const addCollector = () => {
    append({
      id: nanoid(),
      firstName: collectorFirstName,
      lastName: collectorLastName,
      comment: collectorComment,
    })
  }

  const removeCollector = (index: number) => {
    remove(index)
  }
  return (
    <>
      {askForWayHome && (
        <Field label={t({ message: 'Heimweg' }) + ' *'} error={errors?.wayHome}>
          <Controller
            control={control}
            {...register('wayHome')}
            rules={{ required: true }}
            render={({ field }) => (
              <Select id="wayHome" style={{ width: '100%' }} {...field}>
                <Select.Option key={'fetched'} value={'fetched'}>
                  {t({ message: 'Wird abgeholt' })}
                </Select.Option>
                {wayHome === 'partiallyFetched' && (
                  <Select.Option key={'partiallyFetched'} value={'partiallyFetched'}>
                    {t({ message: 'Wird manchmal abgeholt' })}
                  </Select.Option>
                )}
                <Select.Option key={'unfetched'} value={'unfetched'}>
                  {t({ message: 'Geht alleine nach Hause' })}
                </Select.Option>
              </Select>
            )}
          />
        </Field>
      )}
      {(wayHome === 'fetched' || wayHome === 'partiallyFetched') && (
        <>
          <Field label={t({ message: 'Mein Kind darf von folgenden Personen abgeholt werden' }) + ' *'}>
            <Controller
              control={control}
              {...register('collectorsStrategy', {
                validate: (value) => {
                  return value === 'only_guardians' || value === 'exclusive' || value === 'not_specified'
                },
              })}
              render={({ field: { onChange, value } }) => (
                <Radio.Group
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  value={value}
                >
                  <Radio value="only_guardians">{translateCollectorsStrategy('only_guardians')}</Radio>
                  <Radio value="exclusive">{translateCollectorsStrategy('exclusive')}</Radio>
                </Radio.Group>
              )}
            />
          </Field>

          {collectorsStrategy === 'exclusive' && (
            <Field label={t({ message: 'Abholungsberechtigte Personen:' })}>
              <>
                {fields.map((field, index) => {
                  return (
                    <Card size="small" style={{ marginBottom: 8 }} key={nanoid()}>
                      <Row>
                        <Col span={20}>
                          <Field label={t({ message: 'Vorname' })}>
                            <Text {...register(`collectors.${index}.firstName`)}>{field.firstName}</Text>
                          </Field>
                          <Field label={t({ message: 'Nachname' })}>
                            <Text {...register(`collectors.${index}.lastName`)}>{field.lastName}</Text>
                          </Field>
                          <Field label={t({ message: 'Beziehung zu Kind' })}>
                            <Text {...register(`collectors.${index}.comment`)}>{field.comment}</Text>
                          </Field>
                        </Col>
                        <Col span={4}>
                          <Button
                            size="small"
                            style={{ float: 'right' }}
                            shape="circle"
                            icon={<CloseOutlined />}
                            onClick={() => removeCollector(index)}
                          />
                        </Col>
                      </Row>
                    </Card>
                  )
                })}
                <Modal
                  title={t({ message: 'Abholungsberechtigte Person hinzufügen' })}
                  open={isCollectorsModalOpen}
                  onOk={handleCollectorsModalOk}
                  onCancel={handleCollectorsModalCancel}
                >
                  <Field label={t({ message: 'Vorname' })}>
                    <Input
                      type="text"
                      value={collectorFirstName}
                      onChange={(e) => setCollectorFirstName(e.target.value)}
                      maxLength={50}
                    />
                  </Field>
                  <Field label={t({ message: 'Nachname' })}>
                    <Input
                      type="text"
                      value={collectorLastName}
                      onChange={(e) => setCollectorLastName(e.target.value)}
                      maxLength={50}
                    />
                  </Field>
                  <Field label={t({ message: 'Beziehung zu Kind' })}>
                    <Input
                      type="text"
                      value={collectorComment}
                      onChange={(e) => setCollectorComment(e.target.value)}
                      maxLength={1000}
                    />
                  </Field>
                </Modal>

                <Button
                  style={{ marginBottom: 12, width: '100%' }}
                  icon={<PlusOutlined />}
                  onClick={openCollectorModal}
                />
              </>
            </Field>
          )}
        </>
      )}
      {(wayHome === 'unfetched' || wayHome === 'partiallyFetched') && askForBusAndTimeOnWayHome && (
        <Field
          label={
            t({
              message:
                'Nutzt Ihr Kind den ÖV auf dem Heimweg? Dann geben Sie uns bitte die genaue Linie, Busfarbe und Uhrzeit an.',
            }) + `${askForBusAndTimeOnWayHomeMandatory ? ' *' : ''}`
          }
          error={errors?.busLineAndTimeforWayHome}
        >
          <SingleLineTextInput
            control={control}
            required={askForBusAndTimeOnWayHomeMandatory}
            name="busLineAndTimeforWayHome"
            maxLength={1000}
          />
        </Field>
      )}
      {askForSchoolOfferOverLunch && (
        <Field
          label={
            t({
              message:
                'Welches Angebot (Musikunterricht, Sport, Nachhilfe etc.) wird während der Betreuungszeit über den Mittag besucht? Bitte geben Sie auch die Uhrzeit an.',
            }) + `${askForSchollOfferOverLunchMandatory ? ' *' : ''}`
          }
          error={errors?.schoolOfferOverLunch}
        >
          <SingleLineTextInput
            control={control}
            required={askForSchollOfferOverLunchMandatory}
            name="schoolOfferOverLunch"
            maxLength={1000}
          />
        </Field>
      )}
      {askForMenuSelection && (
        <Field
          label={t({ message: 'Menüwahl' }) + `${askForMenuSelectionMandatory ? ' *' : ''}`}
          error={errors?.menuSelection}
        >
          <Controller
            control={control}
            {...register('menuSelection')}
            rules={{ required: askForMenuSelectionMandatory }}
            render={({ field }) => (
              <Select id="menuSelection" style={{ width: '100%' }} {...field}>
                <Select.Option key={'noPreference'} value={'noPreference'}>
                  {translateMenuSelection('noPreference')}
                </Select.Option>
                {institution.registrationConfig.hasMenuWithoutPork && (
                  <Select.Option key={'withoutPork'} value={'withoutPork'}>
                    {translateMenuSelection('withoutPork')}
                  </Select.Option>
                )}
                {institution.registrationConfig.hasMenuWithPorridge && (
                  <Select.Option key={'porridge'} value={'porridge'}>
                    {translateMenuSelection('porridge')}
                  </Select.Option>
                )}
                <Select.Option key={'vegetarian'} value={'vegetarian'}>
                  {translateMenuSelection('vegetarian')}
                </Select.Option>
              </Select>
            )}
          />
        </Field>
      )}
      {(institution?.registrationConfig.askForAllowedToBeAloneOnSchoolYard === 'mandatory' ||
        institution?.registrationConfig.askForAllowedToBeAloneOnSchoolYard === 'optional') && (
        <Field
          label={
            `${
              institution.id === 'cCJtg4Off1HhGbqe4bh0i' // Spezialfeature für Eiken
                ? 'Ab 4. Klasse: '
                : ''
            }` +
            t({
              message:
                'Mein Kind darf sich nach dem Essen und während der Betreuungszeit alleine auf dem Pausenplatz aufhalten',
            }) +
            `${institution?.registrationConfig.askForAllowedToBeAloneOnSchoolYard === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.allowedToBeAloneOnSchoolYard}
        >
          <YesNoRadioButtons
            control={control}
            required={institution?.registrationConfig.askForAllowedToBeAloneOnSchoolYard === 'mandatory'}
            name="allowedToBeAloneOnSchoolYard"
            register={register}
          />
        </Field>
      )}
      {(institution?.registrationConfig.askForAllowedToDoHomework === 'mandatory' ||
        institution?.registrationConfig.askForAllowedToDoHomework === 'optional') && (
        <Field
          label={
            t({
              message: 'Mein Kind soll so weit als möglich die Hausaufgaben während der Betreuungszeit erledigen',
            }) + `${institution?.registrationConfig.askForAllowedToDoHomework === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.allowedToDoHomework}
        >
          <YesNoRadioButtons
            control={control}
            required={institution?.registrationConfig.askForAllowedToDoHomework === 'mandatory'}
            register={register}
            name="allowedToDoHomework"
          />
        </Field>
      )}
      {(institution?.registrationConfig.askForAllowedToBeTransportedByCar === 'mandatory' ||
        institution?.registrationConfig.askForAllowedToBeTransportedByCar === 'optional') && (
        <Field
          label={
            t({
              message:
                'Mein Kind darf für Transporte, in Notfällen oder für kürzere Ausflüge im Privatauto mit vorhandenem Kindersitz transportiert werden?',
            }) + `${institution?.registrationConfig.askForAllowedToBeTransportedByCar === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.allowedToBeTransportedByCar}
        >
          <YesNoRadioButtons
            required={institution?.registrationConfig.askForAllowedToBeTransportedByCar === 'mandatory'}
            control={control}
            register={register}
            name="allowedToBeTransportedByCar"
          />
        </Field>
      )}
      {(institution?.registrationConfig.askForAllowedToReceiveMedicalHelp === 'mandatory' ||
        institution?.registrationConfig.askForAllowedToReceiveMedicalHelp === 'optional') && (
        <Field
          label={
            t({
              message:
                'Mein Kind darf, sofern notwendig, mit Desinfektionsmitteln, Wundsalben o.Ä. erstversorgt werden.',
            }) + `${institution?.registrationConfig.askForAllowedToReceiveMedicalHelp === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.allowedToReceiveMedicalHelp}
        >
          <YesNoRadioButtons
            required={institution?.registrationConfig.askForAllowedToReceiveMedicalHelp === 'mandatory'}
            control={control}
            register={register}
            name="allowedToReceiveMedicalHelp"
          />
        </Field>
      )}

      {(institution?.registrationConfig.askForAllowedToUseTickSpray === 'mandatory' ||
        institution?.registrationConfig.askForAllowedToUseTickSpray === 'optional') && (
        <Field
          label={
            t({
              message:
                'Mein Kind muss mit Zeckenspray eingesprüht werden, falls nicht, darf es nur mit entsprechender Kleidung in den Tagi-Wald (Kleidung ist in der Tagi).',
            }) + `${institution?.registrationConfig.askForAllowedToUseTickSpray === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.allowedToUseTickSpray}
        >
          <YesNoRadioButtons
            required={institution?.registrationConfig.askForAllowedToUseTickSpray === 'mandatory'}
            control={control}
            register={register}
            name="allowedToUseTickSpray"
          />
        </Field>
      )}
      {(institution?.registrationConfig.askForAllowedToUsePublicTransport === 'mandatory' ||
        institution?.registrationConfig.askForAllowedToUsePublicTransport === 'optional') && (
        <Field
          label={
            t({
              message: 'Mein Kind darf in Begleitung von Fachpersonal die öffentlichen Verkehrsmittel benutzen?',
            }) + `${institution?.registrationConfig.askForAllowedToUsePublicTransport === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.allowedToUsePublicTransport}
        >
          <YesNoRadioButtons
            required={institution?.registrationConfig.askForAllowedToUsePublicTransport === 'mandatory'}
            control={control}
            register={register}
            name="allowedToUsePublicTransport"
          />
        </Field>
      )}
      {(institution?.registrationConfig.askForAllowedToBePhotographedForInternalUse === 'mandatory' ||
        institution?.registrationConfig.askForAllowedToBePhotographedForInternalUse === 'optional') && (
        <Field
          label={
            t({
              message:
                'Ich bin einverstanden, dass Fotomaterial mit meinem abgebildeten Kind für interne Zwecke (Fotowände, etc.) verwendet werden darf?',
            }) +
            `${institution?.registrationConfig.askForAllowedToBePhotographedForInternalUse === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.allowedToBePhotographedForInternalUse}
        >
          <YesNoRadioButtons
            control={control}
            required={institution?.registrationConfig.askForAllowedToBePhotographedForInternalUse === 'mandatory'}
            register={register}
            name="allowedToBePhotographedForInternalUse"
          />
        </Field>
      )}
      {(institution?.registrationConfig.askForAllowedToBePhotographedForAds === 'mandatory' ||
        institution?.registrationConfig.askForAllowedToBePhotographedForAds === 'optional') && (
        <Field
          label={
            t({
              message:
                'Ich bin einverstanden, dass Fotomaterial mit meinem abgebildeten Kind für externe Zwecke (Homepage, Schularbeiten, etc.) verwendet werden darf?',
            }) + `${institution?.registrationConfig.askForAllowedToBePhotographedForAds === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.allowedToBePhotographedForAds}
        >
          <YesNoRadioButtons
            control={control}
            required={institution?.registrationConfig.askForAllowedToBePhotographedForAds === 'mandatory'}
            register={register}
            name="allowedToBePhotographedForAds"
          />
        </Field>
      )}
      {(institution?.registrationConfig.askForAllowedToBePhotographedForOtherKids === 'mandatory' ||
        institution?.registrationConfig.askForAllowedToBePhotographedForOtherKids === 'optional') && (
        <Field
          label={
            t({
              message:
                'Ich bin einverstanden, dass Fotomaterial mit meinem abgebildeten Kind für die Portfolios anderer Kinder verwendet werden darf?',
            }) + `${institution?.registrationConfig.askForAllowedToBePhotographedForOtherKids === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.allowedToBePhotographedForOtherKids}
        >
          <YesNoRadioButtons
            control={control}
            required={institution?.registrationConfig.askForAllowedToBePhotographedForOtherKids === 'mandatory'}
            register={register}
            name="allowedToBePhotographedForOtherKids"
          />
        </Field>
      )}
      {(institution?.registrationConfig.askForAllowedToContactTeachers === 'mandatory' ||
        institution?.registrationConfig.askForAllowedToContactTeachers === 'optional') && (
        <Field
          label={
            t({ message: 'Ich erlaube den Austausch zwischen Leitung und Kindergarten bzw. Schulpersonal' }) +
            `${institution?.registrationConfig.askForAllowedToContactTeachers === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.allowedToContactTeachers}
        >
          <YesNoRadioButtons
            required={institution?.registrationConfig.askForAllowedToContactTeachers === 'mandatory'}
            control={control}
            register={register}
            name="allowedToContactTeachers"
          />
        </Field>
      )}
      {(institution?.registrationConfig.askForAllowedToContactSchoolSocialWorkers === 'mandatory' ||
        institution?.registrationConfig.askForAllowedToContactSchoolSocialWorkers === 'optional') && (
        <Field
          label={
            t({ message: 'Ich erlaube den Austausch zwischen Leitung und Schulsozialarbeit' }) +
            `${institution?.registrationConfig.askForAllowedToContactSchoolSocialWorkers === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.allowedToContactSchoolSocialWorkers}
        >
          <YesNoRadioButtons
            required={institution?.registrationConfig.askForAllowedToContactSchoolSocialWorkers === 'mandatory'}
            control={control}
            register={register}
            name="allowedToContactSchoolSocialWorkers"
          />
        </Field>
      )}

      {(institution?.registrationConfig.askIfChildVisitsOtherInstitution === 'mandatory' ||
        institution?.registrationConfig.askIfChildVisitsOtherInstitution === 'optional') && (
        <Field
          label={
            t({
              message: `Besucht Ihr Kind während der Woche noch andere Angebote oder wird zusätzlich zur ${institution.name} fremdbetreut?`,
            }) + `${institution?.registrationConfig.askIfChildVisitsOtherInstitution ? ' *' : ''}`
          }
          error={errors?.visitsOtherInstitution}
        >
          <SingleLineTextInput
            control={control}
            required={institution?.registrationConfig.askIfChildVisitsOtherInstitution === 'mandatory'}
            name="visitsOtherInstitution"
            maxLength={1000}
          />
        </Field>
      )}
      {(institution?.registrationConfig.askIfChildVisitsPhisioTherapy === 'mandatory' ||
        institution?.registrationConfig.askIfChildVisitsPhisioTherapy === 'optional') && (
        <Field
          label={
            t({
              message: `Besucht Ihr Kind die Logopädie, Ergotherapie, etc.?`,
            }) + `${institution?.registrationConfig.askIfChildVisitsPhisioTherapy ? ' *' : ''}`
          }
          error={errors?.visitsPhisioTherapy}
        >
          <SingleLineTextInput
            control={control}
            required={institution?.registrationConfig.askIfChildVisitsPhisioTherapy === 'mandatory'}
            name="visitsPhisioTherapy"
            maxLength={1000}
          />
        </Field>
      )}
      {(institution?.registrationConfig.askIfChildIsVaccinated === 'mandatory' ||
        institution?.registrationConfig.askIfChildIsVaccinated === 'optional') && (
        <Field
          label={
            t({ message: 'Ist ihr Kind geimpft?' }) +
            `${institution?.registrationConfig.askIfChildIsVaccinated === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.isVaccinated}
        >
          <YesNoRadioButtons
            required={institution?.registrationConfig.askIfChildIsVaccinated === 'mandatory'}
            control={control}
            register={register}
            name="isVaccinated"
          />
        </Field>
      )}
      {(institution?.registrationConfig.askIfChildIsTakingNap === 'mandatory' ||
        institution?.registrationConfig.askIfChildIsTakingNap === 'optional') && (
        <Field
          label={
            t({ message: 'Ist ihr Kind an Mittagsschlaf oder –ruhe gewohnt?' }) +
            `${institution?.registrationConfig.askIfChildIsTakingNap === 'mandatory' ? ' *' : ''}`
          }
          error={errors?.isTakingNap}
        >
          <YesNoRadioButtons
            required={institution?.registrationConfig.askIfChildIsTakingNap === 'mandatory'}
            control={control}
            register={register}
            name="isTakingNap"
          />
        </Field>
      )}
      {(institution?.registrationConfig.askIfChildCanPlayAloneOutside === 'mandatory' ||
        institution?.registrationConfig.askIfChildCanPlayAloneOutside === 'optional') && (
          <Field
            label={
            t({ message: 'Darf ihr Kind ohne direkte Aufsicht einer Betreuungsperson draussen spielen?' }) +
            `${institution?.registrationConfig.askIfChildCanPlayAloneOutside === 'mandatory' ? ' *' : ''}`
            }
          error={errors?.isAllowedToBeAloneOutside}
          >
            <YesNoRadioButtons
            required={institution?.registrationConfig.askIfChildCanPlayAloneOutside === 'mandatory'}
              control={control}
              register={register}
            name="isAllowedToBeAloneOutside"
            />
          </Field>
        )}
      {(institution?.registrationConfig.askIfChildCanLeaveYardDuringLunch === 'mandatory' ||
        institution?.registrationConfig.askIfChildCanLeaveYardDuringLunch === 'optional') && (
          <Field
            label={
            t({ message: 'Ist ihr Kind während der Mittagstischzeit berechtigt, den Schulhof zu verlassen, um an den Kiosk oder Volg zu gehen?' }) +
            `${institution?.registrationConfig.askIfChildCanLeaveYardDuringLunch === 'mandatory' ? ' *' : ''}`
            }
            error={errors?.isAllowedToLeaveYardDuringLunch}
          >
            <YesNoRadioButtons
            required={institution?.registrationConfig.askIfChildCanLeaveYardDuringLunch === 'mandatory'}
              control={control}
              register={register}
            name="isAllowedToLeaveYardDuringLunch"
            />
          </Field>
        )}
      <ButtonSection
        submitButtonText={submitButtonText}
        onNext={onSubmitHandler}
        onPrevious={handlePrevious && handlePrevious}
      />
    </>
  )
}

export default ChildImportantInformationForm
